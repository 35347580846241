<template>
    <div class="flex flex-col overflow-hidden  rounded-lg  mb-3" :class="mainClass">
        <div id="image">
            <ClientOnly>
                <Link v-if="!activeCarousel" :href="`/Hotel/${home.slug}`" class="home-card"
                    :class="{ 'home-card--row': isRow }" style="text-decoration: none; color: inherit;">

                <div class="home-image " v-if="home.orderedMedia && home.orderedMedia.length > 0">

                    <v-img :src="home.orderedMedia[0].thumb_url" :alt="`immagine hotel ${home.name}`" height="300" cover
                        :srcset="home.orderedMedia[0].carousel" class="object-cover object-center rounded-3xl">
                    </v-img>
                </div>
                </Link>
            </ClientOnly>
        </div>

        <div class="home-info">
            <h2 :class="{ 'lg:text-4xl': activeCarousel, 'text-base': !activeCarousel }" class="home-title">{{ home.name
                }}
            </h2>
            <!-- Home Review Count -->
            <div class="flex items-center gap-1">
                <template v-if="home['hotel_stars']">
                    <div v-for="(item, index) in Number(home['hotel_stars'])">
                        <SVGStar class="svg" :key="index" />
                    </div>
                </template>
                <div v-else class="m-2">

                </div>
            </div>
            <div v-if="orderListingEnabled">
                <div class="ml-auto" v-if="home.bscore">n.{{ home.bscore_ranking }} a {{ home.city }}</div>
                <div class="ml-auto" v-else>n.d. </div>
            </div>
            <div class="ml-auto" v-else-if="!activeCarousel">
                <div v-for="poi in home.pois?.filter(p => p.type == 'position')"
                    class="font-light text-sm lg:text  mr-2 ml-2">
                    {{ poi.name }} km.{{ poi.distance }}
                </div>
            </div>
            <ClientOnly>
                <Carousel v-if="activeCarousel" :images="home.orderedMedia" :menuimages="home.menuimages" />
            </ClientOnly>
            <div v-if="tagsList">
                <div class="flex items-center">
                    <div>
                        <span class="me-2">{{ tagsText }}</span>

                        <small class="home-tags">{{ home.bscore_tags }}</small>
                    </div>
                    <div>
                        <small>({{ tagsList }})</small>
                    </div>
                </div>
            </div>
            <div v-else-if="home.bscore">

                <div>
                    <span class="me-2">{{ ratingText }}</span>
                    <div class="flex gap-2 items-center">
                        <small class="home-rating">{{ home.bscore }}</small>

                        <small class="text-gray-600">({{ home.sc }} frasi)</small>

                        <ClientOnly>
                            <div class="button-wrapper">
                                <font-awesome-icon icon="info-circle" v-bind="isMobile" class="text-blue-500 text-xl" />

                                <v-tooltip :open-on-click="isMobile" :open-on-hover="!isMobile" activator="parent"
                                    v-model="showTooltip" @click:outside="showTooltip = false" location="top">
                                    <span @click="show = !show">Analizziamo migliaia di frasi di tutte le recensioni
                                        dell'hotel {{ home.name }} e questo è il nostro voto. </span>
                                </v-tooltip>

                            </div>
                        </ClientOnly>
                    </div>
                </div>
            </div>

            <!-- Home Address -->
            <div class="flex items-center gap-2 mt-auto">
                <SVGMarker class="svg" />
                <p>{{ home.address }} - {{ home.city }}</p>
                <template v-if="activeCarousel">
                    <a class="hidden lg:block underline text-blue-600 hover:text-blue-800 visited:text-purple-600 cursor-pointer"
                        @click="$emit('mapOn')"> Mostra Mappa</a>
                    <a class="lg:hidden underline text-blue-600 hover:text-blue-800 visited:text-purple-600 cursor-pointer"
                        @click="$emit('mobileMapOn')"> Mostra Mappa</a>
                </template>
            </div>

            <!-- Home Guests -->
            <div class="flex items-center gap-2">
                <SVGTag class="svg" />
                <p>Migliori caratteristiche: {{ firstRatedTags }} </p><small v-if="otherTags > 0">(+{{ otherTags }})</small>
            </div>
            <Preferred :home="home" />


        </div>

    </div>

</template>

<script>
import { setRatingText, sumReviews } from "@/utils/hotelUtil.js";
import Carousel from './Carousel.vue'
import ClientOnly from './ClientOnly.vue'
import { Link } from "@inertiajs/vue3";

export default {
    props: {
        home: { type: Object, required: true },
        isDeleteVisible: { type: Boolean, required: false, default: false },
        isRow: { type: Boolean, required: false, default: false },
        selected: { type: Number, required: false, default: null },
        showpois: { type: Boolean, required: false, default: false },
        activeCarousel: { type: Boolean, required: false, default: false },
    },
    data() {
        return {
            show: false,
            showTooltip: false,

        }
    },
    mounted() {
        const image = new Image();
        image.src = this.home?.orderedMedia[0]?.thumb_url  ?? null;
    },
    components: { ClientOnly },
    computed: {
        isSSR: function () {
            return import.meta.env.SSR
        },
        orderListingEnabled() {
            return import.meta.env.VITE_ORDERLISTING == 'true'
        },
        isMobile() {
            return this.$vuetify.display.mobile
        },
        images() {
            return this.home.media ? this.home.media.map(i => ({ url: i.original_url })) : []
        },
        mainClass() {
            return [{
                'border-cyan-500 border-4 bg-cyan-50 shadow-md': this.selected == this.home.id,
                'bg-white shadow-lg': this.selected != this.home.id
            },
            { 'p-2': this.activeCarousel }]

        },
        ratingText() {
            return setRatingText(this.home.bscore);
        },
        tagsText() {
            return setRatingText(this.home.bscore_tags);
        },
        tagsList() {
            return this.$page.props.tags?.join(", ");
        },
        tot_reviews() {
            return sumReviews(this.home);
        },
        firstRatedTags() {
            return this.home.tags ? this.home.tags
                .map((t) => t.Tag)
                .slice(0, 2)
                .join(", ") : '';
        },
        otherTags() {
            return this.home.tags?.length - 2;
        },

    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.home-card {
    @apply flex flex-col;

    &--row {
        @apply flex-row;

        >div {
            @apply basis-full;
        }
    }
}

.home-image img {
    @apply w-full h-full object-cover object-center;
}

.home-info {
    @apply py-2 px-2 flex flex-col lg:gap-2 gap-1 font-medium;
}

.home-title {
    @apply font-black text-primary-600
}

.home-delete {
    @apply font-medium flex justify-center py-1 border-t-2 border-red-600 text-red-600 transition-colors hover:bg-red-600 hover:text-white;
}

.home-tags {
    @apply text-base font-medium w-10 h-8 bg-yellow-500 flex items-center justify-center text-black;
}

.home-rating {
    @apply text-base font-medium w-10 h-8 bg-blue-900 text-white flex items-center justify-center;
}

.svg {
    @apply w-4 h-4;
}

.Item {
    overflow: hidden;
    border-radius: 4px;
    width: 100%;

    background: #F5F5F5;
}

.Content {
    padding: 20px;
}

.m_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    line-height: 0;
    display: block;
}
</style>
