<template>

    <div>
        <ClientOnly>
            <button class="heart-button" @click="togglePreferred" :class="exist ? 'selected' : ''">

                <svg v-if="!exist" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" height="1.5rem">
                    <path
                        d="M12 21.35l-1.45-1.32C5.4 16.25 2 12.48 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.98-3.4 7.75-8.55 11.54L12 21.35z" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="0 0 24 24" fill="red"
                    width="100%">
                    <path
                        d="M12 21.35l-1.45-1.32C5.4 16.25 2 12.48 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.98-3.4 7.75-8.55 11.54L12 21.35z" />
                </svg>


            </button>
            {{ !exist ? 'Aggiungi ai Preferiti' : 'Togli dai preferiti' }}
        </ClientOnly>
    </div>

</template>

<script setup>
import { computed, useSSRContext } from 'vue'
import { useCookies } from '@vueuse/integrations/useCookies'
import ClientOnly from './ClientOnly.vue';
const cookies = useCookies(['preferred'], { autoUpdateDependencies: true })
//import { useCookies, createCookies } from '@vueuse/integrations/useCookies'
//import  useUniversalCookies from '@/plugins/universal-cookies';
const props = defineProps({
    home: { type: Object, required: true },
});


const exist = computed(() => {
    const preferred = cookies.get('preferred');
    return preferred ? preferred.includes(props.home.id) : false
})


/* if (import.meta.env.SSR) {
    const ctx = useSSRContext()
    const useCookiesServer = createCookies(ctx.req)
    cookies = useCookiesServer(['preferred'])
}
else */
// cookies = useUniversalCookies(['preferred'])

//const cookies = inBrowser ? useCookies(['preferred']) : createCookies(['preferred'])


const togglePreferred = () => {
    const newItem = props.home.id;
    const preferred = cookies.get('preferred') != undefined ? cookies.get('preferred') : [];

    if (!exist.value) {
        preferred.push(newItem);
        cookies.set('preferred', preferred, '30d');
    }
    else if (exist.value) {
        preferred.splice(preferred.indexOf(newItem), 1);
        cookies.set('preferred', preferred, '30d');
    }
}

</script>
