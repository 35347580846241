<template>
  <div v-if="!isMobile" class="d-none d-md-block thumb-example">
    <swiper class="top-swiper" :style="{
    '--swiper-navigation-color': '#fff',
    '--swiper-pagination-color': '#fff'
  }" :modules="modules" :space-between="10" :navigation="true" :thumbs="{ swiper: thumbsSwiper }">
      <swiper-slide @click="openModal(index)" class="slide" v-for="(image, index) in images" :key="index" :lazy="true">
        <v-img :src="image.thumb_url" :srcset="image.carousel"
          class="cursor-pointer max-w-full flex items-center justify-center h-screen  mx-auto absolute inset-0 left-var" />
        <!-- Centered image -->
      </swiper-slide>
    </swiper>
    <swiper class="thumbs-swiper" :modules="modules" :space-between="10" :slides-per-view="10"
      :watch-slides-progress="true" :prevent-clicks="false" :prevent-clicks-propagation="false"
      @swiper="setThumbsSwiper">
      <swiper-slide class="slide cursor-pointer" v-for="(image, index) in images" :key="index">
        <img :src="image.thumb_url" :srcset="image.carousel" />
      </swiper-slide>
    </swiper>
  </div>


  <v-dialog v-if="!isMobile"  v-model="showModal" :opacity="0.8" overlay-opacity="0.15" :contained="true" eager update="closeModal"
    @update:modelValue="closeModal">
    <div class="thumb-example">
      <swiper class="top-swiper" :style="{
    '--swiper-navigation-color': '#fff',
    '--swiper-pagination-color': '#fff',
    '--swiper-navigation-sides-offset': '10px',
  }" :modules="modules" :space-between="10" :navigation="true" :thumbs="{ swiper: thumbsSwiperModal }"
        :initial-slide="selectedImageIndex">
        <swiper-slide class="slide" v-for="(image, index) in images" :key="index">
          <v-img :crop="true" :src="image.thumb_url" :srcset="image.carousel"
            class="cursor-pointer max-w-full flex items-center justify-center h-screen  mx-auto absolute inset-0 left-var">
          </v-img>
        </swiper-slide>
      </swiper>
      <swiper class="thumbs-swiper" :space-between="10" :slides-per-view="10" :watch-slides-progress="true"
        :prevent-clicks="false" :prevent-clicks-propagation="false" @swiper="setThumbsSwiperModal">
        <swiper-slide class="slide cursor-pointer" v-for="(image, index) in images" :key="index">
          <img :src="image.thumb_url" :srcset="image.carousel" />
        </swiper-slide>
      </swiper>
    </div>

  </v-dialog>

   <swiper :style="{
    '--swiper-navigation-color': '#fff',
    '--swiper-pagination-color': '#fff',
    '--swiper-navigation-sides-offset': '10px',
  }" :modules="modules" :space-between="10" :navigation="true" class="d-md-none top-swiper" :slides-per-view="1"
    :watch-slides-progress="true" :prevent-clicks="false" :prevent-clicks-propagation="false" :breakpoints="{
    320: {
      slidesPerView: 1,
      spaceBetween: 10
    },
    480: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    640: {
      slidesPerView: 3,
      spaceBetween: 30
    }
  }">
    <swiper-slide class="slide cursor-pointer" v-for="(image, index) in images" :key="index" :lazy="true">
      <img v-if="image.mobile_menu != ''" :src="image.mobile_menu" :srcset="image.carousel" style="height: 200px;"
        class="max-w-full flex items-center justify-center mx-auto inset-0 left-var swiper-lazy" loading="lazy" />
    </swiper-slide>
  </swiper> 
</template>

<script>
import { defineComponent, ref, nextTick, computed } from 'vue'
import { Navigation, Thumbs } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useDisplay } from 'vuetify';

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'



export default defineComponent({
  name: 'carousel',
  title: 'Thumbs gallery with Two-way control',
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['images', 'menuimages'],
  setup() {

    const spaceBetween = 10;
    const showModal = ref(false);
    const selectedImageIndex = ref(20);
    const thumbsSwiper = ref()
    const thumbsSwiperModal = ref()
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper
    }
    const setThumbsSwiperModal = (swiper) => {
      console.log("ma fa questo?")
      setTimeout(() => {
        thumbsSwiperModal.value = swiper
      }, 100);
      // nextTick(()=>thumbsSwiperModal.value = swiper)

    }

    const openModal = (index) => {
      console.log(document.body.classList)
      document.body.classList.toggle("noscroll");
      selectedImageIndex.value = index;
      console.log("selectedImageIndex.value", selectedImageIndex.value)
      showModal.value = true;
    }
    const closeModal = () => {
      console.log("close", document.body.classList)
      document.body.classList.toggle("noscroll")
      showModal.value = false;
    }

    const { mobile } = useDisplay();
    const isMobile = computed(() => {
      return mobile.value
    });
    return {
      modules: [Navigation, Thumbs],
      setThumbsSwiper,
      setThumbsSwiperModal,
      thumbsSwiper,
      thumbsSwiperModal,
      openModal,
      closeModal,
      showModal,
      selectedImageIndex,
      isMobile
      }
  }
})
</script>

<style lang="scss">
.v-overlay__scrim {
  opacity: 0.8;
}

.noscroll {
  overflow: hidden;
  height: 100vh;
}

.scroll {
  overflow: unset;
  height: unset;
}

.thumb-example {
  height: 90vh;
  min-height: 30vh;
  background-color: white;
  padding: 50px;
}

.top-swiper {
  padding-bottom: 10px;
}

/* .top-swiper {
  left: 50%;
  top: 50%;
} */

.thumbs-swiper {
  .slide {
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      width: auto;
      height: 76px;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }
}

.top-swiper {
  height: 80%;
  width: 100%;
}

.thumbs-swiper {
  height: 20%;
  box-sizing: border-box;
  padding: 1 0;

  .slide {
    width: 25%;
    height: 100%;
    opacity: 1;

    &:not(.swiper-slide-thumb-active) {
      opacity: 0.4;
    }
  }
}

.swiper-button-prev::after,
.swiper-button-next::after {
  border-color: white;
  border-style: solid;
  content: "";
  height: 10px;
  margin: -6px;
  position: absolute;
  top: 50%;
  width: 10px;


}

.swiper-button-prev::after {
  border-width: 0 0 3px 3px;
  right: 44%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);

}

.swiper-button-next:after {
  border-width: 0 0 3px 3px;
  left: 44%;
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);

}

.swiper-button-prev,
.swiper-button-next {
  background: rgba(0, 0, 0, .32);

}
</style>