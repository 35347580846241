<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 15"
    class="fill-[#FFB136]"
  >
    <g fill-rule="evenodd">
      <g fill-rule="nonzero">
        <g>
          <path
            d="M24.301 209.649l3.574-1.879 3.574 1.879c.634.333 1.392-.203 1.269-.922l-.684-3.978 2.89-2.817c.52-.505.233-1.389-.484-1.493l-3.995-.582-1.785-3.62c-.32-.646-1.247-.654-1.57 0l-1.785 3.62-3.995.582c-.717.104-1.004.988-.484 1.493l2.89 2.817-.684 3.978c-.123.72.635 1.258 1.27.922z"
            transform="translate(-1024 -1806) translate(1004 1611)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
